<template>
    <div class="two fields" :class="{required}">
        <div class="field">
            <label>{{label}}</label>
            <select ref="dropdown" class="ui dropdown" :required="required" @change="changeDropdown">
                <option value="" v-if="hasEmptyValue">{{emptyValuePlaceholder}}</option>
                <option v-for="(description, key) in selection" :key="`selection_${key}`" :value="key" :selected="key === values.dropdown">{{description}}</option>
            </select>
        </div>
        <div class="field" v-if="values.dropdown === otherValue">
            <label>{{secondaryLabel}}</label>
            <input ref="freetext" type="text" :value="values.freetext" @input="changeFreetext" required/>
        </div>
    </div>
</template>

<script>
export default {
    name: "combo-dropdown",
    props: {
        value: {
            type: String
        },
        label: {
            type: String,
            required: true
        },
        secondaryLabel: {
            type: String,
            default: ""
        },
        selection: {
            type: Object,
            required: true
        },
        otherValue: {
            type: String,
            default: ""
        },
        hasEmptyValue: {
            type: Boolean,
            default: true
        },
        emptyValuePlaceholder: {
            type: String,
            default: ""
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dropdown_value: "",
            freetext_value: ""
        };
    },
    computed: {
        values() {
            let values = {
                dropdown: this.dropdown_value,
                freetext: this.freetext_value
            };

            if(this.value) {
                let has_dropdown_value = false;
                for(const value in this.selection) {
                    if(value === this.otherValue) {
                        continue;
                    }

                    if(value === this.value) {
                        values.dropdown = this.value;
                        has_dropdown_value = true;

                        break;
                    }
                }

                if(!has_dropdown_value) {
                    values.dropdown = this.otherValue;
                    values.freetext = this.value;
                }
            }

            return values;
        }
    },
    methods: {
        changeDropdown() {
            this.dropdown_value = this.$refs.dropdown.value;
            this.freetext_value = (this.$refs.freetext?.value || "").trim();

            if(this.dropdown_value !== this.otherValue) {
                this.$emit("input", this.dropdown_value);
            } else {
                this.$emit("input", this.freetext_value);
            }

            this.focusNext();
        },
        changeFreetext() {
            this.dropdown_value = this.$refs.dropdown.value;
            this.freetext_value = (this.$refs.freetext?.value || "").trim();

            if(this.dropdown_value === this.otherValue) {
                this.$emit("input", this.freetext_value);
            } else {
                this.$emit("input", this.dropdown_value);
            }
        },
        focusNext() {
            if(this.values.dropdown === this.otherValue) {
                this.$nextTick(() => {
                    $(this.$refs.freetext).focus();
                });
            }
        }
    }
};
</script>